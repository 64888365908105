import React, { useState, useEffect, useRef, memo } from "react";
import TimeAgo from "react-timeago";
import firebase from "firebase";
import { useHistory } from "react-router-dom";
import { getRouteToDetail, ClientRoutes } from "../../constants/routes";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  Feed,
  Loader,
  Image,
  Icon,
  Button,
  Input,
  Label,
} from "semantic-ui-react";
import { Column, Row, Title2 } from "../feeds/styles";
import { sendProxyMessage, viewMessage } from "../../api/api";

interface IRoom {
  _id: string;
  avatar: string;
  isBlocked: boolean;
  preview: string;
  updatedAt: number;
  userId: string;
  userName: string;
}

interface IConvo {
  _id: string;
  avatar: string;
  isViewed: boolean;
  receiverId: string;
  createdAt: number;
  userId: string;
  userName: string;
  text: string;
}

const pageLimit = 20;

const Conversation = memo(
  ({
    me,
    you,
    messagable,
  }: {
    me: string;
    you: string;
    messagable?: boolean;
  }) => {
    const [convos, setConvos] = useState<IConvo[]>([]);
    const [convos2, setConvos2] = useState<IConvo[]>([]);
    const [hasMorePage, setHasMorePages] = useState(false);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const { push } = useHistory();

    const [input, setInput] = useState("");

    const ref = useRef<firebase.database.Query>();
    const ref2 = useRef<firebase.database.Query>();
    useEffect(() => {
      setPage(1);
      ref.current = firebase
        .database()
        .ref(`/chats/conversations/${me}/${you}`)
        .orderByChild("createdAt");
      ref2.current = firebase
        .database()
        .ref(`/chats/conversations/${you}/${me}`)
        .orderByChild("createdAt");
    }, [me, you]);

    useEffect(() => {
      ref.current?.limitToLast(pageLimit * page).on(
        "value",
        (response) => {
          if (response?.exists()) {
            setHasMorePages(
              Object.keys(response.val()).length === pageLimit * page
            );

            setConvos(
              Object.entries(response.val() as object).reduce((acc, entry) => {
                const [key, value] = entry;
                return [...acc, { ...value, _id: key }];
              }, [] as any[])
            );
          }

          setLoading(false);
        },
        console.warn
      );

      return () => {
        ref.current?.off();
      };
    }, [page, me, you]);

    useEffect(() => {
      ref2.current?.limitToLast(pageLimit * page).on(
        "value",
        (response) => {
          if (response?.exists()) {
            setConvos2(
              Object.entries(response.val() as object).reduce((acc, entry) => {
                const [key, value] = entry;
                return [...acc, { ...value, _id: key }];
              }, [] as any[])
            );
          }
          setLoading(false);
        },
        console.warn
      );

      return () => {
        ref2.current?.off();
      };
    }, [page, me, you]);

    const _sendProxyMessage = async () => {
      const payload = {
        text: input,
        // The server will add these for us
        // userName: username,
        // avatar,
        isRead: false,
        userId: me,
        senderId: me,
        receiverId: you,
        createdAt: Date.now(),
      };

      await sendProxyMessage(payload);
    };

    const nextPage = () => {
      if (hasMorePage) {
        setPage(page + 1);
      }
    };

    const handleViewProfileClick = async (userId: string) => {
      if (userId) {
        push(getRouteToDetail(ClientRoutes.Users, userId));
      }
    };

    if (loading) return <Loader active>{"Loading Messages..."}</Loader>;

    return (
      <Feed>
        {messagable ? (
          <Feed.Event>
            <Input
              style={{ width: "100%" }}
              value={input}
              onChange={(event, data) => setInput(data.value)}
            />
            <Button
              primary
              onClick={async () => {
                await _sendProxyMessage();
                setInput("");
              }}
            >
              {"Send Message"}
            </Button>
          </Feed.Event>
        ) : null}
        <Icon fitted corner="top left" name="circle" color={"grey"}>
          <Label>{"is read by me"}</Label>
        </Icon>
        <Icon fitted corner="top left" name="circle" color={"grey"}>
          <Label>{"is read by them"}</Label>
        </Icon>
        {convos
          .sort((a, b) => b.createdAt - a.createdAt)
          .map((c, i) => (
            <Message
              key={c._id}
              message={c}
              message2={convos2[i]}
              userId={me}
              handleViewProfileClick={handleViewProfileClick}
            />
          ))}
        <Button onClick={nextPage} disabled={!hasMorePage}>
          {"Show More"}
        </Button>
      </Feed>
    );
  }
);

export const ChatRooms = ({
  userId,
  onDismiss,
  messagable,
}: {
  userId: string;
  onDismiss: () => void;
  messagable?: boolean;
}) => {
  const [rooms, setRooms] = useState<(IRoom & IConvo)[]>([]);
  const [hasMorePage, setHasMorePages] = useState(false);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [conversationOpen, setConversationOpen] = useState("");
  const { push } = useHistory();

  const ref = useRef<firebase.database.Query>();

  useEffect(() => {
    setLoading(true);
    ref.current = firebase
      .database()
      .ref(`/chats/rooms/${userId}`)
      .orderByChild("updatedAt");

    return () => {
      ref?.current?.off();
    };
  }, [userId]);

  useEffect(() => {
    ref?.current?.limitToLast(pageLimit * page).on("value", (response) => {
      if (response?.exists()) {
        setHasMorePages(
          Object.keys(response.val()).length === pageLimit * page
        );

        setRooms(
          Object.entries(response.val() as object).reduce((acc, entry) => {
            const [key, value] = entry;
            return [...acc, { ...value, _id: key }];
          }, [] as any[])
        );
      }
      setLoading(false);
    });
  }, [page, userId]);

  const nextPage = () => {
    if (hasMorePage) {
      setPage(page + 1);
    }
  };

  const handleViewProfileClick = async (userId: string) => {
    if (userId) {
      onDismiss();
      push(getRouteToDetail(ClientRoutes.Users, userId));
    }
  };

  return (
    <Row style={{ height: "90vh" }}>
      <Column
        style={{
          overflowY: "scroll",
          height: "90vh",
          maxWidth: "33%",
          padding: "8pt",
        }}
      >
        {/* <Title2>{`${hasMorePage}`}</Title2> */}
        <InfiniteScroll
          dataLength={rooms.length}
          next={() => nextPage()}
          hasMore={hasMorePage}
          loader={loading && <Loader active>{"Finding Rooms..."}</Loader>}
        >
          <Feed>
            {rooms
              .sort((a, b) => b.updatedAt - a.updatedAt)
              .map((room) => {
                return (
                  <Room
                    key={room._id}
                    userId={userId}
                    room={room}
                    handleViewProfileClick={handleViewProfileClick}
                    onSelect={setConversationOpen}
                    selected={conversationOpen === room._id}
                  />
                );
              })}
          </Feed>

          <Button onClick={nextPage} disabled={!hasMorePage}>
            {"Show More"}
          </Button>
        </InfiniteScroll>
      </Column>

      <Column
        style={{
          overflowY: "scroll",
          height: "90vh",
          width: "100%",
          padding: "8pt",
        }}
      >
        {conversationOpen ? (
          <Conversation
            me={userId}
            you={conversationOpen}
            messagable={messagable}
          />
        ) : null}
      </Column>
    </Row>
  );
};

const Room = ({
  room,
  handleViewProfileClick,
  userId,
  onSelect,
  selected,
}: any) => {
  const backgroundColor = selected ? "#12dc0433" : "transparent";

  return (
    <Feed.Event
      style={{ backgroundColor, padding: "8pt", borderRadius: "4pt" }}
    >
      <Feed.Label>
        <Image src={room.avatar} />
      </Feed.Label>
      <Feed.Content>
        <Feed.Summary>
          <Feed.User
            as="a"
            onClick={() => {
              handleViewProfileClick(room._id);
            }}
          >
            {room.userName}
          </Feed.User>
          <Feed.Date>
            <TimeAgo date={room.updatedAt || room.createdAt} />
          </Feed.Date>
          <Feed.Extra text>{room.preview || room.text}</Feed.Extra>
          <Feed.Extra text>
            <Button size="tiny" onClick={() => onSelect(room._id)}>
              <Icon name="caret right" />
            </Button>
          </Feed.Extra>
        </Feed.Summary>
        <Feed.Meta>
          <Feed.Label content={room.isBlocked === true ? "blocked" : ""} />
        </Feed.Meta>
      </Feed.Content>
    </Feed.Event>
  );
};

const Message = ({ message, handleViewProfileClick, message2 }: any) => {
  return (
    <Feed.Event key={message?.updatedAt || message.createdAt}>
      <Icon
        fitted
        corner="top left"
        name="circle"
        color={message.isRead ? "green" : "red"}
        size="tiny"
      />
      <Icon
        fitted
        corner="top left"
        name="circle"
        color={message2?.isRead ? "green" : "red"}
        size="tiny"
      />
      <Feed.Label>
        <Image circular src={message.avatar} />
      </Feed.Label>
      <Feed.Content>
        <Feed.Summary>
          <Feed.User
            as="a"
            onClick={() => {
              handleViewProfileClick(message._id);
            }}
          >
            {message.userName}
          </Feed.User>
          <Feed.Date>
            <TimeAgo date={message.updatedAt || message.createdAt} />
          </Feed.Date>
          <Feed.Extra text>{message.text}</Feed.Extra>
        </Feed.Summary>
        {!message.isRead ? (
          <Feed.Extra>
            <Button
              size="tiny"
              compact
              basic
              onClick={() => viewMessage(message.receiverId, message.userId)}
            >
              {"mark as read"}
            </Button>
          </Feed.Extra>
        ) : null}
        {message.isBlocked === true ? (
          <Feed.Meta>
            <Feed.Label content={"blocked"} />
          </Feed.Meta>
        ) : null}
      </Feed.Content>
    </Feed.Event>
  );
};
